@import './_variables';

* {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-family: hero-new, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #e7522a;
  line-height: 37px;
}

h2 {
  font-family: louvette-display, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 60px;
}

h3 {
  font-family: hero-new, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #f1d2d2;
}

p {
  font-family: hero-new, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
}

@media screen and (min-width: 1025px) {
  .desktop-hidden {
    display: none;
  }

  .img-section:hover .image {
    opacity: 0.15;
  }

  .img-section:hover .middle {
    opacity: 1;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    visibility: visible;
    z-index: 2;
  }

  .middle div h2 {
    color: white;
    text-transform: uppercase;
    font-family: hero-new, sans-serif;
    font-weight: 700;
    font-size: 40px;
    padding: 2rem 0;
  }

  .middle div h3 {
    color: #e7522a;
  }
}

/*CONTENT*/
header {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  border-bottom: 0.5px solid #e7522a;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

.logo {
  width: 350px;
}

header a {
  padding: 2rem;
}

header nav {
  padding: 0 2rem;
  display: flex;
  align-items: center;
}

header nav a {
  color: #e7522a;
  text-decoration: none;
  font-family: hero-new, sans-serif;
  font-weight: 700;
  font-size: x-large;
  padding: 0 1rem;
}

header nav a:hover {
  color: #0b1431;
}

#white_links {
  color: #fff !important;
}

.mobile-hidden .rex {
  width: 35px;
}

.menu-btn {
  display: none;
}

.hero {
  background-color: #f1d2d2;
  min-height: 100vh;
  width: -webkit-fill-available;
  padding: 5rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}



@media (max-width: 600px) {
  #new_hero {
    min-height: 100vh;
    /* padding-top: 150px; */
    padding-bottom: 50px;
    height: 100%;
  }
}





.hero h1 {
  font-size: 130px;
  line-height: 130px;
  text-transform: uppercase;
}

.showreel {
  display: flex;
  align-self: flex-end;
  padding: 2rem 0;
  position: absolute;
  bottom: 0;
}

@media (max-width: 480px) {
  .showreel {
    position: absolute;
    right: 10px;
  }
}

.showreel img {
  width: 20px;
  padding-left: 5px;
  height: 100%;
  width: 100%;
}

#showreel-btn,
.rex-btn,
.mobile-rex-btn {
  cursor: pointer;
  color: #e7522a;
  background-color: transparent;
  border: none;
  font-family: hero-new, sans-serif;
  font-weight: 700;
  font-size: x-large;
}

#showreel-btn:hover {
  text-decoration: underline;
}

.modal,
.rex-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(231, 82, 42, 0.7);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  width: fit-content;
}

iframe {
  display: block;
}

/*MAIN CONTENT*/
main {
  max-width: 1300px;
}

.section-1 {
  padding: 5rem 0;
  display: flex;
  justify-content: space-between;
}

.section-2 {
  padding: 5rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
}

.section-2 .text-box {
  padding-inline: 30px;
}

@media (max-width: 600px) {
  .section-2 .text-box {
    padding-inline: 15px;
  }
}

.text-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.text-box p {
  line-height: 27px;
}

@media (max-width: 767px) {
  .text-box p {
    padding: 20px;
  }
}

/*BUTTON HOVER EFFECT*/
.c-button {
  font-family: hero-new, sans-serif;
  font-weight: 700;
  text-decoration: none;
  padding: 2rem;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
}

.c-button--gooey {
  color: #e7522a;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #e7522a;
  border-radius: 50%;
  padding: 2.5rem 1rem;
  position: relative;
  transition: all 700ms ease;
  z-index: 1;
}

.c-button__blobs {
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  z-index: -1;
  border-radius: 50%;
}

.c-button--gooey .c-button__blobs div {
  background-color: #e7522a;
  width: 40%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transform: scale(1) translateY(125%) translateZ(0);
  transition: all 700ms ease;
  overflow: hidden;
}

.c-button--gooey .c-button__blobs div:nth-child(1) {
  left: -5%;
}

.c-button--gooey .c-button__blobs div:nth-child(2) {
  left: 30%;
  transition-delay: 60ms;
}

.c-button--gooey .c-button__blobs div:nth-child(3) {
  left: 66%;
  transition-delay: 25ms;
}

.c-button--gooey:hover {
  color: #fff;
}

.c-button--gooey:hover .c-button__blobs div {
  transform: scale(1.4) translateY(0) translateZ(0);
}

.btn2 {
  color: black;
  border: 2px solid black;
  margin-top: 2rem;
}

.c-button--gooey .blobs2 div {
  background-color: black;
}

/*BUTTON HOVER EFFECT FINISHED*/

.some-links {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.some-links i {
  color: black;
  font-size: xx-large;
}

.img-section {
  background-color: #0b1431;
  position: relative;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.img-section .image,
.img-section .middle {
  transition: 0.5s ease;
}

.img-section img {
  width: 100%;
  height: 100%;
  display: block;
  max-height: 950px;
}

.branding-heading {
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: white;
  font-size: 20px;
}

.cuckoo {
  padding: 5rem;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 730px) {
  .cuckoo {
    flex-direction: column;
    align-items: center;
    padding: 4rem;
  }

  .text-box {
    max-width: 515px;
    align-items: center;
    padding: 20px;
    width: 100%;
  }

  .text-box h2 {
    text-align: center;
  }
}

.text-box-2 {
  max-width: 100%;
  width: 100%;
  padding: 5px 0;
  max-width: 800px;
}

@media(max-width: 1250px) {
  .text-box-2 p {
    padding: 5px 30px;
  }
}



.cuckoo-box {
  height: 20rem;
  width: 20rem;
  background-color: #feff01;
  background-image: url("/assets/instagram.jpg");
  background-position: center;
  background-size: contain;
  align-self: center;
}

@media (max-width: 850px) {
  .cuckoo-box {
    width: 15rem;
    height: 17rem;
  }
}

.follow-btn {
  padding: 1rem 0;
  height: 6rem;
}

.follow-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.two-img-div {
  display: flex;
  align-items: flex-end;
}

.single-div {
  padding: 5rem;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact {
  margin: 8rem 0;
}

@media (max-width: 1300px) {
  .contact {
    padding-left: 10px;
  }
}

@media (max-width: 830px) {
  .contact {
    padding-left: 30px;
  }
}

@media (max-width: 475px) {
  .contact {
    padding-left: 10px;
  }
}

.contact h1 {
  padding: 1rem 0;
}

.contact a {
  color: unset;
}

/*FOOTER*/
footer {
  width: -webkit-fill-available;
  background-color: #e7522a;
  position: relative;
  z-index: 3;
  /* margin-top: 1rem; */
}

.img-div {
  padding: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 2rem;
  align-items: center;
}

@media (max-width: 830px) {
  .img-div {
    padding: 2rem;
  }

  .img-div>img {
    max-width: 10rem !important;
  }
}

@media (max-width: 560px) {
  .img-div {
    grid-template-columns: repeat(2, 1fr);
  }

  .img-div>img {
    max-width: 10rem !important;
  }
}

.img-div>img {
  max-width: 15rem;
  justify-self: center;
}

.some-container {
  height: 6rem;
  border-top: 0.5px solid #f1d2d2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.some-div-footer {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
}

.some-div-footer>a {
  text-decoration: none;
}


img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;

}


.video_mp4_container {
  max-width: 600px;
  max-height: 500px;
  width: 100%;
  height: 100%;

}


#center-mb-col {
  align-items: center;
  gap: 25px;

}

@media (max-width: 900px) {
  #center-mb-col {
    display: block;

    padding-left: 30px;
  }
}


@media (max-width: 700px) {
  #center-mb-col {
    flex-direction: column;
    align-items: flex-start;
    padding: 35px 0px;
  }
}


.video_mp4_container_large {
  width: 100%;
  height: 100%;
}

.flex_block_image_text {
  display: flex;
  padding: 50px 0;
  gap: 45px;
  justify-content: space-between;
}


.align-center {
  align-items: center;
  justify-content: center;
}

.flex_block_image_text .image,
.flex_block_image_text .text_block,
.text_block_end {
  flex: 0.5;
}

.flex_block_image_text p {
  font-size: 19px;
  line-height: 34px;
}

.flex_block_image_text h4 {
  font-family: hero-new, sans-serif;
  font-weight: bold;
  font-size: clamp(20px, 3vw, 24px);
  line-height: clamp(26px, 3vw, 36px);
}

@media (max-width: 768px) {
  .flex_block_image_text {
    flex-direction: column;
    padding: 30px 15px;
  }
}

.text_block_right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media (max-width: 768px) {
  .text_block_right {
    justify-content: flex-start;
  }

  .flex_block_image_text .image,
  .flex_block_image_text .text_block,
  .text_block_end {
    flex: 1;
  }
}

.img-medium img {
  max-width: 100%;
  width: 100%;
  background-size: cover;
  object-fit: cover;
}

.flex_block_image_text .text_block,
.text_block_end {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
}

.text_block_end {
  justify-content: flex-end;
}


.citat {
  font-size: clamp(32px, 4vw, 52px) !important;
  line-height: clamp(42px, 4vw, 78px) !important;
  max-width: 500px;
}



#hero_image {
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
}

.PlayButton_module_playButtonWrapper__af9e9913 {
  display: none !important;
}



.video_hero {
  background-color: #f1d2d2;
  height: 100vh;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  object-fit: cover;
  z-index: 1;
  position: absolute;
}

#hero_image .mobile-hidden {
  padding: 5rem 2rem 5rem 2rem;
  z-index: 1;
}

#hero_image .desktop-hidden {
  padding: 5rem 1rem 5rem 2rem;
  z-index: 1;
}

#hero_video .mobile-hidden {
  z-index: 1;
  padding: 5rem 2rem 0 2rem;
}

#hero_video .desktop-hidden {
  z-index: 1;
  padding: 5rem 2rem 0 2rem;
}

#hero_video h1 {
  text-transform: uppercase;
  font-size: clamp(40px, 7vw, 130px) !important;
  line-height: clamp(50px, 7vw, 130px) !important;
}

#hero_image h1 {
  text-transform: uppercase;
  font-size: clamp(30px, 7vw, 130px) !important;
  line-height: clamp(40px, 7vw, 130px) !important;
}

.centered_parapgrah {
  max-width: 800px;
  margin: 0 auto;
}

.centered_parapgrah p {
  font-size: 19px;
  line-height: 34px;
}


.centered_quote {
  max-width: 1210px;
  margin: 0 auto;
  text-align: center;
}

.p-t-100 {
  padding-top: 100px;
}

.p-100 {
  padding: 100px 0;
}

.large_quote {
  font-size: clamp(30px, 5vw, 70px);
  line-height: clamp(38px, 6vw, 80px);
  font-weight: 300;
}

.small_quote {
  max-width: 1140px;
  font-size: clamp(25px, 4vw, 40px) !important;
  line-height: clamp(40px, 5vw, 60px) !important;
  font-weight: 300;
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 1000px) {
  .large_quote {
    font-size: 70px;
    line-height: 80px;
  }

  .small_quote {
    font-size: 40px !important;
    line-height: 60px !important;
  }
}

@media(min-width: 1400px) {
  .mw-1300 {
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
  }

  .mw-1400 {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
  }
}



.bold_quote_name {
  padding-top: 50px;
  font-weight: bold;
  font-size: clamp(16px, 3vw, 18px);
  line-height: clamp(24px, 3vw, 34px);
}

.full_width_image img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}




.flex-05 {
  flex: 0.5;
}

.centered_ballgrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: -50px;
  place-items: center;
  padding: 0 10px;
  gap: 35px;
  margin: 0 auto;
  max-width: 700px;
  margin-top: -50px;
}

.centered_ballgrid .item {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.centered_ballgrid .item .heading {
  font-size: clamp(25px, 4vw, 35px);
  line-height: clamp(35px, 4vw, 48px);
  color: #F1D2D2;
  font-weight: 700;
  padding-bottom: 20px;
}

.centered_ballgrid .item .paragraph {
  font-size: clamp(18px, 3vw, 20px);
  line-height: clamp(23px, 4vw, 25px);
  color: #F1D2D2;
  max-width: 178px;
  font-weight: 700;
}

@media(min-width: 580px) {
  .centered_ballgrid {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media(min-width: 1200px) {
  .centered_ballgrid {
    grid-template-columns: repeat(4, 1fr);
    max-width: 1300px;
  }
}

@media (min-width: 500px) {
  .md-hidden {
    display: none !important;
  }
}


#stanford_iframe {
  max-width: 850px;
  height: 572px;
  width: 100%;
}


.p-50 {
  padding: 50px 0;
}

@media (max-width: 767px) {
  .p-md-25 {
    padding: 25px 0;
  }
}

@media (max-width: 1300px) {
  .md-p-45 {
    padding: 0 45px;
  }

  .lg-p-45 {
    padding: 0 45px;
  }

}

@media (max-width: 1000px) {
  .md-column {
    flex-direction: column;
  }

  .md-center {
    align-items: center;
  }

  .md-p-t-50 {
    padding-top: 50px;
  }

  .md-p-0 {
    padding: 0px;
  }

  #stanford_iframe {
    max-width: 100%;
  }


  .centered_parapgrah {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .align-center {
    align-items: flex-start;
  }

  .lg-p-45 {
    padding: 0;
  }

  .md-p-0 {
    padding: 30px 0;
  }

  #stanford_iframe {
    height: 370px;
  }

  #hero_video .flex_block_image_text {
    padding: 30px 0;
  }
}

@media (max-width: 500px) {
  #stanford_iframe {
    height: 244px;
  }




  .centered_ballgrid {
    margin-top: 0;
    padding: 50px 0;
  }

  .small_quote {
    color: #E7522A;
  }

  #black {
    color: #000 !important;
  }

  .centered_quote {
    background-color: #F1D2D2;
    padding: 30px 30px;
  }

  .sm-align-center {
    text-align: center !important;
  }

  .ingress {
    font-size: 17px !important;
    line-height: 30px !important;
  }

  .sm-p-0 {
    padding: 0;
  }

  .sm-p-50 {
    padding: 50px 0;
  }

  .sm-p-t-50 {
    padding-top: 50px;
  }

  .sm-hidden {
    display: none;
  }


  .large_quote {
    color: #E7522A;
    font-size: 30px;
    line-height: 38px;
    padding: 0 30px;
  }

  .bold_quote_name {
    color: #E7522A;
    font-size: 14px;
    padding-top: 30px;
  }

  .sm-p-30 {
    padding: 30px 0;
  }

  .sm-m-30 {
    margin: 50px 0;
  }

  #sm-column_reverse {
    flex-direction: column-reverse;
  }


}

@media (max-width: 420px) {
  #hero_video .flex_block_image_text {
    gap: 0;
  }



  #hero_video {
    height: 75vh;
  }

  #sm-m-t-130 {
    margin-top: 130px;
  }

  #stanford_iframe {

    height: 200px;

  }


}








.hero_content_container {
  max-width: 1010px;
}

@media (min-width: 1150px) {
  .hero_content_container .inner_content {
    padding-top: 50px;
  }
}

.hero_content_container .inner_content p {
  color: #fff !important;
  font-family: hero-new, sans-serif !important;
  font-size: 18px !important;
  line-height: 30px !important;
}

.hero_content_container .button_container {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

.hero_content_container .button_container a {
  background-color: #fff;
  color: #E7522A;
  text-transform: uppercase;
  max-height: 53px;
  height: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: hero-new, sans-serif !important;
  text-align: center;
  padding: 15px 35px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-decoration: underline;
}

.hero_content_container .button_container img {
  margin-left: 5px;
  flex: 0.1;
}

.hero_content_container .button_container a:nth-child(n+2) {
  margin-left: 30px;
}




.video_text_container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #F1D2D2;

}

@media (min-width: 2000px) {
  .video_text_container {
    max-height: 1170px;
  }
}


.video_text_container.row-reverse {
  flex-direction: row-reverse;
}




.video_text_container .video_container,
.text_container {
  flex: 0.5;
}

.video_text_container .video_container {
  overflow: hidden;
  z-index: 2;
  width: 100%;
}

.video_text_container .text_container .inner_content {
  margin: 0 auto;
  max-width: 500px;
}



.video_text_container .text_container .inner_content .text_content .content_gap {
  margin-top: 30px;
}

.video_text_container .text_container .inner_content .text_content .content_gap:nth-child(1) {
  margin-top: 5px;
}

.video_text_container .video_container video {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
}




.video_text_container .text_container h2 {
  color: #E7522A;
  font-weight: 600;
  line-height: 98px;
}

.video_text_container .text_container .p-bold {
  font-size: 30px;
  line-height: 52px;
  font-weight: 700;
}

.video_text_container .text_container p {
  font-size: 16px;
  line-height: 27px;
}

.animated_button_container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  flex: 0.7;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 100px;
}



.animated_button_container a {
  text-decoration: underline;
  font-family: "Louvette display";
  font-size: 30px;
  font-weight: 500px;
  line-height: 52px;
  cursor: pointer;
}

.video_text_container .text_container .inner_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* height: 100%; */
  padding: 100px 0;
}

.video_text_container .text_container .inner_content.text-white {
  color: #fff;
}


.animation_block_price {
  background-color: #fff;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  position: relative;
  margin-left: -700px;
  margin-right: 20px;
  z-index: 1;
  width: 100%;
  display: none;
  flex: 1;
  max-width: 500px;
  animation-name: example;
  animation-duration: 1s;
  animation-timing-function: linear;
}

/* The animation code */
@keyframes example {
  from {
    margin-left: -700px
  }

  to {
    margin-left: 0;
  }
}



.animation_container {
  display: flex;
  align-items: center;
  position: relative;
  transition: 1s ease-in-out;
  margin-top: -30px;
  padding-bottom: 30px;
}

.animation_container.active {
  margin-left: 0px;
}

.animation_block_price.active {
  margin-left: 0;
  display: flex;
  position: relative;
}




.animation_block_price .inner_text {
  max-width: 275px;
}

.animation_block_price .inner_text p {
  max-width: 300px;
}

.animation_block_price .inner_text .bold {
  font-weight: 700 !important;
  font-size: 16px !important;
}



@media (max-width: 1350px) {
  .animated_button_container {
    flex: 1;
  }
}


@media (max-width: 1068px) {
  .video_text_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero_content_container .inner_content {
    padding-right: 30px;
  }

  .text_content {
    padding-bottom: 50px;
  }

  .video_text_container .text_container {
    width: 100%;
  }

  .animated_button_container {
    padding: 0 30px;
    margin: 0;
  }



  .hero_content_container {
    padding: 30px 0;
  }

  .animation_container.active {
    margin-left: -30px;
  }

  .button_container {
    flex-wrap: wrap;
  }


  .video_text_container .text_container .inner_content {
    padding: 0 45px;
  }


  .hero_content_container .button_container a {
    margin: 20px 10px;
    margin-left: 0;
  }

  .hero_content_container .button_container a:nth-child(2),
  .hero_content_container .button_container a:nth-child(4) {
    margin-left: 30px;
  }


  .video_text_container .text_container .inner_content {
    padding: 30px;
    margin: 0;
  }
}


@media (max-width: 1010px) {

  .hero_content_container .button_container a:nth-child(4) {
    margin-left: 0px;
  }
}

@media (max-width: 985px) {

  .hero_content_container .button_container a:nth-child(3),
  .hero_content_container .button_container a:nth-child(4) {
    margin-left: 0;
  }

  .button_container {
    max-width: 400px;
  }

}

@media (max-width: 850px) {
  .hero {
    padding-top: 150px;
  }






  .video_text_container .text_container .inner_content,
  .animated_button_container {
    padding: 45px;

  }

  .animated_button_container {
    padding-top: 0;
  }

  .video_text_container .text_container .inner_content h2 {
    font-size: 60px !important;
    line-height: 70px !important;
    padding-bottom: 30px;
  }


  .video_text_container .text_container .p-bold {
    line-height: 42px;
  }


  .text_content {
    padding-right: 30px;
  }
}

@media (max-width: 600px) {
  .animation_block_price {
    max-width: 400px;
  }



  .animated_button_container a {
    font-size: 27px;
  }

  .animation_block_price {
    padding: 15px;
  }

  .animation_block_price .inner_text {
    padding-right: 15px;
  }

  .video_text_container .text_container .p-bold {
    margin-bottom: 3px;
  }

  .text_content {
    padding-right: 0;
  }

  .hero_content_container .button_container a {
    padding: 15px 12px;
  }

  .video_text_container .text_container .inner_content,
  .animated_button_container {
    padding: 5rem 1rem 0 1rem;

  }

  .animation_container.active {
    margin-left: 0;
  }


  .hero {
    padding-bottom: 50px !important;
  }
}



@media (max-width: 362px) {

  .hero_content_container .button_container a:nth-child(2),
  a:nth-child(4) {
    margin-left: 0px;
  }
}


.centered_text_container {
  background-color: #F1D2D2;
  display: flex;
  flex-direction: column;
  padding: 150px 0;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.centered_text_container div {
  box-sizing: border-box;
}

.centered_text_container .inner {
  max-width: 735px;
  width: 100%;
  margin: 0 auto;
}

.centered_text_container .inner.inner-large {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
}

.centered_text_container .inner .bold {
  font-weight: 700;
  line-height: 30px;
}

.centered_text_container .inner p {
  font-weight: 300;
  line-height: 30px;
}



.double_video_container {
  display: flex;
  width: 100%;
  position: relative;
  min-height: 760px;
}

.double_video_container .video_container {
  flex: 0.5;
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
}

.double_video_container .video_container.flex-start {
  justify-content: flex-start;
}


.double_video_container .video_container .bold {
  font-weight: 700;
  color: #fff;
  line-height: 30px;
}

.double_video_container .video_container .video_text {
  position: relative;
  max-width: 520px;
  width: 100%;
  color: #fff;
  z-index: 2;
  padding-right: 130px;
}

.double_video_container .video_container.flex-start .video_text {
  padding-left: 130px;
  padding-right: 0;
}

.double_video_container .video_container .video_text p {
  line-height: 30px;
}


.double_video_container .video_container video {
  width: 50vw;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.double_video_container .video_container::after {
  content: "";
  position: absolute;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  /* Black background with opacity */
}



.double_video_container .video_logo {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  width: 100%;
  top: 120px;
}

.double_video_container .video_button {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}




@media (max-width: 1400px) {
  .double_video_container .video_container .video_text {
    padding: 30px;
  }


  .lg-p-30 {
    padding: 30px !important;
  }

  .lg-p-tb-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .double_video_container .video_logo {
    padding: 30px;
  }

  .double_video_container .video_logo,
  .video_text_kont {
    max-width: 1100px !important;
  }

  .double_video_container .video_container.flex-start .video_text {
    padding-left: 30px;
  }

  .double_video_container {
    min-height: 1000px;
  }

}


@media (max-width: 1200px) {



  .double_video_container .video_logo,
  .video_text_kont {
    max-width: 850px !important;
  }

  #video_text_under {
    padding-left: 80px;
  }

  .md-column {
    flex-direction: column;
  }


  .double_video_container .video_container video {
    min-height: 600px;
  }

  .double_video_container .video_button {
    bottom: 80px;
  }

  .flex_container .paragraph {
    padding-top: 50px;
  }


}






@media (max-width: 1000px) {




  .double_video_container .video_logo,
  .video_text_kont {
    max-width: 750px !important;
  }


  .double_video_container .video_container video {
    width: 100%;
  }




  .centered_text_container .inner p {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 900px) {
  .centered_text_container {
    padding: 100px 0;
  }

  .double_video_container .video_button {
    bottom: 80px;
  }

  .double_video_container .video_container {
    justify-content: flex-start;
  }

  .double_video_container .video_container .video_text,
  .double_video_container .video_container.flex-start .video_text {
    padding-left: 150px;
  }

}


@media (max-width: 820px) {

  .double_video_container,
  .video_text_kont {
    flex-direction: column;
  }



  .double_video_container .video_container {
    min-height: 100vh;
  }


  .double_video_container .video_logo {}


  .video_text_kont {
    max-width: 400px !important;
    left: 30% !important;
    top: 50% !important;
    transform: translateX(-50%) !important;
    justify-content: space-between !important;
    transform: translateY(-50%) !important;
  }

  .double_video_container .video_logo {
    left: -45% !important;
    top: 50%;
  }

  .video_text_kont .video_text {
    margin: 50px 0;
  }

  #video_text_under {
    padding-left: 0;
  }



  .double_video_container .video_logo {
    left: -230px;
    max-width: 500px;
    transform: rotate(90deg);
    width: 100%;
    right: 100%;
  }

}


@media (max-width: 768px) {
  .double_video_container {
    flex-direction: column;
  }



}

.text_container_centered {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
}

.flex_container {
  display: flex;
  padding: 100px 0;
  flex-wrap: wrap;
}

.flex_container.justify_between {
  justify-content: space-between;
}


.flex_container .heading {
  max-width: 680px;
  flex: 0.5;
}

.flex_container .paragraph {
  max-width: 520px;
  flex: 0.5;
  color: #fff;
}

.flex_container .paragraph p {
  line-height: 30px;
}

.flex_container .paragraph .bold {
  font-weight: 700;
  padding-bottom: 15px;
}

.flex_container .heading h2 {
  font-family: louvette-display;
  color: #fff;
  font-size: 126px;
  line-height: 144px;
  font-weight: 600;
  max-width: 600px;
}

.text_container_centered .centered_button {
  text-align: center;
  padding-bottom: 100px;
  position: relative;
}


.full_video_section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

@media (min-width: 1920px) {
  .full_video_section video {
    width: 100%;
  }
}


.full_video_section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.full_video_section .text_block {
  position: absolute;
  max-width: 520px;
  padding-right: 16%;
  color: #fff;
  z-index: 1;
}

@media (max-width: 1800px) {
  .full_video_section .text_block {
    padding-right: 14%;
  }
}

@media (max-width: 1600px) {
  .full_video_section .text_block {
    padding-right: 8%;
  }
}

@media (max-width: 1300px) {
  .full_video_section .text_block {
    padding-right: 4%;
  }
}


.full_video_section .text_block h2 {
  font-family: hero-new, sans-serif;
  font-weight: 300;
  font-size: 50px;
  line-height: 75px;
}

.full_video_section .text_block .quote {
  padding-top: 50px;
}

.full_video_section .text_block .bold {
  font-size: 24px;
  line-height: 41px;
  font-family: hero-new, sans-serif;
  font-weight: 700;
  padding-bottom: 15px;
}

.full_video_section .text_block .xl_p {
  font-weight: 300;
  font-family: hero-new, sans-serif;
  font-size: 24px;
  line-height: 41px;
}

.full_video_section .text_block .button_container {
  padding-top: 50px;
}

.full_video_section .text_block .button_container a {
  width: 80px;
  text-align: center;
  line-height: 14px;
}


.desktop-hidden video,
.mobile-hidden video {
  width: 100%;
  margin-bottom: -5px;
  height: 100%;
  object-fit: cover;
}




@media (max-width: 700px) {
  /* .double_video_container .video_container video {
    min-height: 850px;
  } */


  .full_video_section .text_block h2 {
    font-size: 31px;
    line-height: 47px;
  }

  .double_video_container .video_container .video_text,
  .double_video_container .video_container.flex-start .video_text {
    max-width: 300px;
  }

  #video_text_under {
    top: 100px;
  }

  .flex_container .heading h2 {
    font-size: 54px;
    line-height: 62px;
  }

  .full_video_section .text_block {
    padding-left: 30px;
  }

  .full_video_section .text_block .bold,
  .full_video_section .text_block .xl_p {
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 10px;
  }

}


@media (max-width: 600px) {
  .video_text_kont {
    left: 22% !important;
  }

  .full_video_section .text_block {
    padding-top: 350px;
  }

  .full_video_section {
    min-height: 1000px;
  }




  #scroll_stick {
    /* overflow: scroll; */
    /* scroll-snap-type: y mandatory;
    height: 100vh; */
  }

  .double_video_container .video_container {
    height: 200vh;
    /* scroll-snap-stop: always;
    scroll-snap-align: start; */
  }


  .full_video_section video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .double_video_container .video_container {
    min-height: 100vh;
  }

  .double_video_container .video_logo {
    padding: 0;
  }
}

@media (max-width: 530px) {
  .video_text_kont {
    left: 15% !important;
  }

  .video_text_kont .video_text,
  .video_text_kont {
    max-width: 350px !important;
  }
}

@media (max-width: 500px) {

  .double_video_container .video_container .video_text,
  .double_video_container .video_container.flex-start .video_text {
    padding-left: 100px;
  }





  .full_video_section {
    height: 100vh;
  }


  .full_video_section .text_block {
    max-width: 300px;
    padding-top: 200px;
    padding-right: 80px;
  }
}


@media (max-width: 430px) {

  .double_video_container .video_container .video_text,
  .double_video_container .video_container.flex-start .video_text {
    padding-left: 100px;
    padding-right: 0;
    max-width: 286px;
  }

  .video_text_kont .video_text,
  .video_text_kont {
    max-width: 300px !important;
  }

  .double_video_container .video_logo {
    left: -190px;
  }
}

@media (max-width: 395px) {

  .double_video_container .video_container .video_text,
  .double_video_container .video_container.flex-start .video_text {
    max-width: 270px;
  }

  .double_video_container .video_container .video_text p,
  .double_video_container .video_container.flex-start .video_text p {
    padding-right: 10px;
  }

  .double_video_container .video_container .video_text,
  .double_video_container .video_container.flex-start .video_text {
    padding-left: 70px;
  }


  .double_video_container .video_logo {
    left: -180px;
  }
}

@media (max-width: 365px) {

  .video_text_kont .video_text,
  .video_text_kont {
    max-width: 250px !important;
  }
}

@media (max-width: 345px) {

  .double_video_container .video_container .video_text,
  .double_video_container .video_container.flex-start .video_text {
    padding-left: 50px !important;
  }


}



.centered_flex_column_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  background-color: #F1D2D2;
  padding: 200px 0;
}

@media (max-width: 1000px) {
  .centered_flex_column_container {
    padding: 150px 0;
  }
}

@media (max-width: 600px) {
  .centered_flex_column_container {
    padding: 100px 0;
  }
}

.centered_flex_column_container h2 {
  font-size: 70px;
  line-height: 80px;
  font-family: "hero-new";
  font-weight: 700;
}

.centered_flex_column_container .display_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  max-width: 80%;
  padding-left: 5px;
}

@media (max-width: 1030px) {
  .centered_flex_column_container .display_flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .centered_flex_column_container p {
    padding-bottom: 30px;
  }
}

.centered_flex_column_container p {
  max-width: 635px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

#btn_ml_30 {
  margin-left: 30px;
}





@media (max-width: 1200px) {
  .md-p-30 {
    padding: 30px;
  }
}

@media (max-width: 600px) {
  .centered_flex_column_container h2 {
    font-size: 44px;
    line-height: 60px;
  }

  .centered_flex_column_container .display_flex {
    max-width: 100%;
  }



}



@media (max-width: 500px) {
  .sm_column {
    flex-direction: column;
  }

  .sm-p-15 {
    padding: 15px;
  }

  .centered_flex_column_container p {
    padding-bottom: 50px;
  }



}







@media (max-width: 480px) {
  .showreel {
    bottom: -20px;
  }

  .double_video_container .video_button {
    bottom: 80px;
  }


  #video_text_under {
    margin-top: 100px;
  }
}




.video_text_kont {
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  top: 40%;
  max-width: 1300px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
}

.video_text_kont .video_text {
  max-width: 520px;
  width: 100%;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}

.video_text_kont .video_text .bold {
  font-weight: 700;
}


.hero_video__container {
  position: relative;
  height: 100vh;
}

.start_video_hero {
  width: 100%;
  position: relative;
}

@media (min-width: 1920px) {
  .start_video_hero {
    min-height: 700px;
  }
}




.start_video_hero .desktop-hidden video {
  margin-bottom: -5px;
}


@media (max-width: 500px) {
  .desktop-hidden #startsida_toppvideo {
    height: 100vh;
    width: 100%;
    min-width: 100vw;
    object-fit: cover;
  }
}



.form_container {
  position: fixed;
  right: 3%;
  bottom: 5%;
  max-width: 435px;
  width: 100%;
  background-color: #e7522a;
  border-radius: 24px;
  display: flex;
  align-items: center;
  z-index: 3;
  overflow: hidden;
  display: none;
}

#mc_embed_signup {
  width: 91%;
}

.form_container .form_container_btn {
  position: absolute;
  top: 8px;
  right: 12px;
  border-top-right-radius: 12px;
  outline: none;
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
  z-index: 2;
  font-size: 20px;
  font-weight: 300;
}

.form_container_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 25px 33.6px;
}


.gorilla_container {
  position: absolute;
  bottom: -4px;
  right: 0;
  border-bottom-right-radius: 24px;
  overflow: hidden;
}

#mc_embed_signup .button {
  margin-top: 15px !important;
  font-family: "hero-new" !important;
  background-color: transparent !important;
  border: 1px solid #fff !important;
  font-size: 14px !important;
}



.form_container #mc_embed_signup form {
  margin: 0 !important;
}

.form_container_inner h2 {
  color: #fff;
  font-size: 18px;
}

.form_container_inner input {
  max-width: 80%;
  max-height: 35px;
  height: 100%;
  margin-top: 25px;
  padding-left: 8px;
  font-family: "hero-new";
  font-size: 12px;
  padding: 15px 8px;
  border: none !important;
}

.form_container_inner p {
  max-width: 90%;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}


#mc_embed_signup div#mce-responses {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

#mc_embed_signup #mce-error-response {
  color: #fff !important;
  font-family: "hero-new";
  font-size: 18px;
  font-weight: 300 !important;
  width: 100% !important;
  margin: 0;
}

#mc_embed_signup #mce-success-response {
  color: #fff !important;
  font-family: "hero-new";
  font-size: 18px;
  font-weight: 300 !important;
  width: 100% !important;
  margin: 0;
}







@media (max-width: 900px) {


  .form_container_inner p,
  .form_container_inner h2 {
    font-size: 14px;
  }

}


#mc_embed_signup h2 {
  font-family: "hero-new" !important;
  font-weight: 800 !important;
  margin: 0 !important;
  font-size: 18px !important;
}




@media (max-width: 500px) {
  .form_container {
    right: 50%;
    transform: translateX(50%);
    bottom: 4%;
    max-width: 95vw;
  }

  .form_container_inner {
    padding: 25px 33.6px;
    padding-left: 10px;
  }

  .form_container_inner h2,
  .form_container_inner p {
    padding-left: 5px !important;
  }

  #mc_embed_signup .button {
    margin-left: 5px;
  }

  .form_container_inner p {
    margin-top: 10px;
  }

  .gorilla_container {
    bottom: -19px;
  }

  .form_container_inner input {
    margin-left: 5px;
  }
}

@media (max-width: 345px) {
  .form_container {

    max-width: 300px;
  }

  .smm-p-15 {
    padding: 15px;
  }
}



.contact_popup_form_close {
  display: none;
  position: absolute;
  right: 0;
  top: 20%;
  z-index: 999;
  border: none;
  outline: none;
  color: #fff;
  background-color: transparent;
  font-size: 36px;
  cursor: pointer;
  font-family: "hero-new";
}

.contact_popup_form {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  align-items: center;
  justify-content: center;
  background: #E7522A;
}

.contact_popup_form_inner {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 250px;
  margin: 0 auto;
}



.contact_popup_form_inner h3 {
  max-width: 295px;
  text-align: center;
  padding-bottom: 25px;
}

.contact_popup_form_inner input {
  margin: 15px 0;
  max-height: 40px;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  border: none;
  outline: none;
  border-radius: 3px;


}

.contact_popup_form_container {
  height: 100%;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
}

form .contact_popup_form_inner button {
  border: none;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
  max-height: 40px;
  height: 100%;
  cursor: pointer;
  font-family: "hero-new";
  font-size: 20px;
  background-color: #fff;
  margin-top: 15px !important;
  font-family: "hero-new" !important;
  background-color: transparent !important;
  border: 1px solid #fff !important;
  font-size: 14px !important;
  color: #fff;
  border-radius: 4px;
  max-width: 120px;
  transition: 0.3s ease-in-out;
}

#send_in_contact_form:hover,
#mc_embed_signup .button:hover {
  color: #E7522A !important;
  background: #fff !important;
}





.peter_call_you,
.contact_popup_inner a {
  border: 1px solid #fff;
  /* padding: 12px 15px; */
  height: 100%;
  max-width: 200px;
  width: 100%;
  outline: none;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  margin: 0;
  font-size: 14px;
  font-family: "hero-new";
  background-color: #fff;
  color: #E7522A;
  max-width: 200px;
  width: 100%;
  max-height: 50px;
  height: 100%;
  padding: 13px 0;
  transition: 0.3s ease-in-out;
}

.peter_call_you:hover,
.contact_popup_inner a:hover {
  background-color: transparent;
  color: #fff;
}

.contact_popup_inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  max-width: 78%;
  margin: 0 auto;
}

.close_contact_popup {
  border: none;
  outline: none;
  font-family: "hero-new";
  background-color: transparent;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 5px;
  cursor: pointer;
  display: none;
}

.close_contact_popup.active {
  display: block;
}

.contact_popup {
  position: relative;
  width: 250px;
  background-color: #E7522A;
  text-align: center;
  max-width: 250px;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  display: none;
  z-index: 2;
}


.line_through_container {
  padding: 20px 0;
}

.line_through {
  text-transform: uppercase;
  color: #fff;
  font-family: "hero-new";
}

.line_through::before,
.line_through::after {
  border-top: 1px solid #fff;
  content: "";
  left: 95px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-100%);
  width: 67px;
  right: -66px;
}


.line_through::after {
  left: 88px;
  right: -10px;
  transform: translateY(-50%) translateX(100%);
}


@media (max-width: 600px) {
  .contact_popup {
    width: 235px;
    top: -90px;
  }
}

@media (max-width: 500px) {
  .contact_popup {
    top: -70px;
  }

  .centered_flex_column_container h2 {
    font-size: 35px;
  }
}




.name_error,
.email_error,
.phone_error {
  display: none;
}


.contact_popup_form_success_message,
.contact_popup_form_loading_message {
  display: none;
}

.contact_popup_form_success_message.active,
.contact_popup_form_loading_message.active {
  display: block;
}





.name_error.active,
.email_error.active,
.phone_error.active {
  display: block;
  font-size: 14px;
}

@media (max-width: 400px) {
  .contact_popup_form_inner {
    width: 70%;
  }

  .contact_popup_form_close {
    right: 20px;
  }
}


#autocirc_logo {
  max-width: 200px;
}

@media (max-width: 800px) {
  #autocirc_logo {
    max-width: 120px !important;
  }
}

@media (max-width: 500px) {
  #autocirc_logo {
    max-width: 100px !important;
  }
}

#open_contact_popup {
  background-color: transparent;
}



.hero_2023_title {
  /* font-size: clamp(47px, 10vw, 220px);
  line-height: clamp(65px,10vw, 240px); */
  font-size: 220px;
  line-height: 240px;
  font-family: "louvette-display";
  font-weight: 600;
  position: absolute;
  left: 3%;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  text-transform: uppercase;
  max-width: 1300px;
}


@media (max-width: 1400px) {
  .hero_2023_title {
    font-size: 180px;
    line-height: 200px;
  }
}


@media (max-width: 1200px) {
  .hero_2023_title {
    font-size: 140px;
    line-height: 160px;
  }
}

@media (max-width: 1000px) {
  .hero_2023_title {
    font-size: 120px;
    line-height: 140px;
  }
}

@media (max-width: 770px) {
  .hero_2023_title {
    font-size: 100px;
    line-height: 120px;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .hero_2023_title {
    font-size: 75px;
    line-height: 85px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .hero_2023_title {
    font-size: 65px;
    line-height: 85px;
    text-align: center;
  }
}


@media (max-width: 430px) {
  .hero_2023_title {
    font-size: 58px;
    line-height: 68px;
    text-align: center;
    left: 0;
  }
}

@media (max-width: 360px) {
  .hero_2023_title {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
  }
}


.case_image_container {
  display: flex;
  justify-content: space-between;

}

.case_image_container .item_container {
  position: relative;
  flex: 0.5;
  margin: 0 20px;
}


#xeed_iframe {
  height: 100%;
  width: 100%;
}







@media (max-width: 600px) {
  .case_image_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .case_image_container .item_container {
    width: 100%;
    flex: 1;
  }

  #xeed_iframe {
    min-height: 600px;
    width: 100%;
    margin-top: -50px;
  }

  .hero_xeed {
    background-image: url(/assets/case/Xeed/xeed-mobiltopp.jpg) !important;
  }


}

.md-display-show {
  display: none;
}


@media (max-width: 1000px) {
  .md-display-none {
    display: none;
  }

  .md-display-show {
    display: block;
  }
}

.#{$prefix}text-align-left {
  text-align: left;
}

.#{$prefix}text-align-center {
  text-align: center;
}

.#{$prefix}text-align-right {
  text-align: right;
}

@each $key, $val in $breakpoints {
  @media screen and (min-width: #{$val}) {
    .#{$key + $separator} {
      &#{$prefix}text-align-left {
        text-align: left;
      }

      &#{$prefix}text-align-center {
        text-align: center;
      }

      &#{$prefix}text-align-right {
        text-align: right;
      }
    }
  }
}